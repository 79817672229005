import { createRouter, createWebHashHistory } from 'vue-router';
import { defineAsyncComponent } from 'vue';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'index',
      component: defineAsyncComponent(() => import('@/pages/home/index.vue')),
    },
    {
      path: '/game',
      name: 'game',
      component: defineAsyncComponent(() => import('@/pages/game/index.vue')),
    },
    {
      path: '/*',
      redirect: '/',
    },
  ],
});
router.beforeEach((to, from, next) => {
  // console.log(to, from)
  next();
});
router.afterEach((to, from) => {
  // console.log('afterEach')
});

export default router;
